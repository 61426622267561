<template>
  <div>
    <a-spin :spinning="loadings.page_open">
      <sdPageHeader :title="$t.customerInfo" />
      <Main>
        <BasicFormWrapper class="mb-25">
          <sdCards>
            <a-form
              layout="vertical"
              :model="formState"
              @finish="saveDetail()"
            >
              <a-form-item
                :label="$t.company_name"
                :rules="[{ required: true }]"
                :extra="$t.company_name_hint"
              >
                <a-input
                  v-model:value="formState.company"
                  :placeholder="$t.company_name"
                />
              </a-form-item>

              <a-form-item
                :label="$t.name_surname"
                :rules="[{ required: true }]"
                :extra="$t.name_surname_hint"
              >
                <a-input
                  v-model:value="formState.name"
                  :placeholder="$t.name_surname"
                />
              </a-form-item>

              <a-form-item
                :label="$t.customer_profile_email_label"
                :rules="[{ required: true }]"
                :extra="$t.customer_profile_email_hint"
              >
                <a-input
                  v-model:value="formState.contact_email"
                />
              </a-form-item>

              <a-form-item
                :label="$t.telephone"
                :rules="[{ required: true }]"
              >
                <a-input
                  v-model:value="formState.tel"
                  type="text"
                  :placeholder="$t.telephone"
                  @keydown="isPhoneNumber($event)"
                />
              </a-form-item>
              <a-form-item
                :label="$t.address"
                :rules="[{ required: true }]"
              >
                <a-input
                  v-model:value="formState.address"
                  type="text"
                  :placeholder="$t.address"
                />
              </a-form-item>
              <a-form-item
                :label="$t.country"
                :rules="[{ required: true }]"
              >
                <a-select
                  v-model:value="formState.country"
                  size="large"
                  name="basic-select"
                  class="sDash_fullwidth-select"
                  placeholder="Ülke Seçiniz"
                  show-search
                >
                  <a-select-option
                    key="Turkey"
                    value="TÜRKİYE"
                    selected
                  >
                    Türkiye
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item
                :label="$t.city"
                :rules="[{ required: true }]"
              >
                <a-select
                  v-model:value="formState.city"
                  size="large"
                  name="basic-select"
                  class="sDash_fullwidth-select"
                  placeholder="İl Seçiniz"
                  show-search
                  @change="getTownViaCity"
                >
                  <a-select-option
                    v-for="item in cityList"
                    :key="item.code"
                    :value="item.name"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item
                :label="$t.town"
                :rules="[{ required: true }]"
              >
                <a-select
                  v-model:value="formState.town"
                  size="large"
                  name="basic-select"
                  class="sDash_fullwidth-select"
                  placeholder="İlçe Seçiniz"
                  show-search
                >
                  <a-select-option
                    v-for="town in townList"
                    :key="town.id"
                    :value="town.name"
                  >
                    {{ town.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item
                :label="$t.district"
                :rules="[{ required: true }]"
              >
                <a-input
                  v-model:value="formState.district"
                  type="text"
                  :placeholder="$t.district"
                />
              </a-form-item>
              <a-form-item
                :label="$t.tax_administration"
                :rules="[{ required: true }]"
              >
                <a-input
                  v-model:value="formState.taxOffice"
                  :placeholder="$t.tax_administration"
                />
              </a-form-item>
              <a-form-item
                :label="$t.tax_number"
                :rules="[{ required: true }]"
              >
                <a-input
                  v-model:value="formState.taxNumber"
                  :placeholder="$t.tax_number"
                  :maxlength="11"
                  @keydown="isNumber($event)"
                />
              </a-form-item>
              <a-form-item>
                <div class="sDash_form-action">
                  <a-button
                    class="btn-signin"
                    type="primary"
                    html-type="submit"
                    :loading="loadings.form_submit"
                  >
                    {{ $t.save }}
                  </a-button>
                </div>
              </a-form-item>
            </a-form>
          </sdCards>
        </BasicFormWrapper>
      </Main>
    </a-spin>
  </div>
</template>

<script>
// hem tsoft-kargo hem de cargong tarafından kullanılıyor

import { BasicFormWrapper, Main } from '@/view/styled'
import { notification } from 'ant-design-vue'
import { nextTick } from 'vue'

export default {
  name: 'SettingPage',
  components: {
    BasicFormWrapper,
    Main,
  },
  data() {
    return {
      loadings: {
        page_open: false,
        form_submit: false,
      },
      cityList: [],
      townList: [],
      formState: {
        company: null,
        name: null,
        contact_email: null,
        tel: null,
        address: null,
        country: null,
        city: null,
        town: null,
        district: null,
        taxOffice: null,
        taxNumber: null,
      },
    }
  },
  mounted() {
    this.loadings.page_open = true
    this.$restMethods.postForWebApp('/settings-page-open').then(response => {
      if ( ! response.hasError()) {
        const { form, cities } = response.getData()

        this.formState.company = form.company
        this.formState.name = form.name
        this.formState.contact_email = form.contact_email
        this.formState.tel = this.unformatPhoneNumber(form.tel)
        this.formState.address = form.address
        this.formState.country = form.country
        this.formState.city = form.city
        this.formState.town = form.town
        this.formState.district = form.district
        this.formState.taxOffice = form.taxOffice
        this.formState.taxNumber = form.taxNumber

        this.cityList = cities
      }
      this.loadings.page_open = false
    })
  },
  methods: {
    formatPhoneNumber(value) {
      const onlyNums = value.replace(/[^\d]/g, '') // Sadece sayıları al
      if (onlyNums.length < 4) return onlyNums
      if (onlyNums.length < 7) return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`
      return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)} ${onlyNums.slice(6, 8)} ${onlyNums.slice(8, 10)}`
    },
    isPhoneNumber: function(evt) {
      evt = evt || window.event
      const charCode = evt.which || evt.keyCode
      const inputElement = evt.target

      // Input'ta zaten 15'den (parantezler ve boşluklar dahil) fazla karakter varsa ekstra girişi engelle
      if (inputElement.value.length >= 15 && charCode !== 8 && charCode !== 46) {
        // 8: Backspace, 46: Delete
        evt.preventDefault()
        return false
      }

      // Eğer silme veya delete tuşlarına basılmışsa formatlamayı atla
      if (charCode === 8 || charCode === 46) {
        return true
      }

      // Sadece sayıların girişine izin ver
      if (charCode > 31 && (charCode < 48 || charCode > 57) && (charCode < 96 || charCode > 105)) {
        evt.preventDefault()
        return false
      } else {
        nextTick(() => {
          inputElement.value = this.formatPhoneNumber(inputElement.value)
        })
        return true
      }
    },
    isNumber: function(evt) {
      evt = evt || window.event
      const charCode = evt.which || evt.keyCode

      if (charCode === 8 || charCode === 46) {
        return true
      }

      if (charCode > 31 && (charCode < 47 || charCode > 57) && (charCode < 96 || charCode > 105)) {
        evt.preventDefault()
        return false
      }
    },
    getKeyByValue(value) {
      for (const city of this.cityList) {
        if (city.name === value) {
          return city.code
        }
      }
      return null
    },
    getTownViaCity(value) {
      const keyOfCity = this.getKeyByValue(value)
      if (keyOfCity === null) {
        return false
      }
      this.loadings.page_open = true
      this.$restMethods.postForWebApp('/get-towns/' + keyOfCity).then(response => {
        if ( ! response.hasError()) {
          this.formState.town = ''
          this.townList = response.getData().items
        }
        this.loadings.page_open = false
      })
    },
    formattedPhoneNumber(phoneNumberString) {
      let cleanNumber = phoneNumberString.replace(/[^\d]/g, '')
      return '0' + cleanNumber
    },
    unformatPhoneNumber(value) {
      let nums = value.replace(/\D/g, '') // Sadece sayıları al
      if (nums.startsWith('0')) {
        nums = nums.substring(1) // Başındaki '0' karakterini kaldır
      }

      // Sayıları uygun formata dönüştür
      if (nums.length === 10) {
        return `(${nums.slice(0, 3)}) ${nums.slice(3, 6)} ${nums.slice(6, 8)} ${nums.slice(8)}`
      }

      // Verilen numara uygun uzunlukta değilse ham haliyle dön
      return value
    },
    isAddressValid(address) {
      return address && address.length >= 10 && /\d/.test(address)
    },
    saveDetail() {
      const validationRules = [
        { key: 'company', message: this.$t.company_required },
        { key: 'name', message: this.$t.name_required },
        { key: 'address', message: this.$t.address_required },
        { key: 'tel', message: this.$t.tel_required, minLength: 15 },
        { key: 'country', message: this.$t.country_required },
        { key: 'city', message: this.$t.city_required },
        { key: 'taxOffice', message: this.$t.tax_office_required },
        { key: 'taxNumber', message: this.$t.tax_number_required, minLength: 10, maxLength: 11 },
        { key: 'town', message: this.$t.town_required },
      ]

      for (const { key, message, minLength, maxLength } of validationRules) {
        const value = this.formState[key]
        if (
          value == null ||
          value.length === 0 ||
          (minLength && value.length < minLength) ||
          (maxLength && value.length > maxLength)
        ) {
          this.openNotificationWithIcon('error', this.$t.error, message)
          return // Hata varsa işlemi durdur
        }
      }

      // Telefon numarası formatlama
      if ( ! this.isPhoneNumberValid(this.formState.tel)) {
        this.openNotificationWithIcon('error', this.$t.error, this.$t.tel_invalid)
        return
      }

      // Adres geçerlilik kontrolü
      if ( ! this.isAddressValid(this.formState.address)) {
        this.openNotificationWithIcon('error', this.$t.error, this.$t.address_invalid)
        return
      }

      // Herhangi bir hata yoksa, form verilerini gönder
      this.sendFormDetails()
    },

    isPhoneNumberValid(tel) {
      const cleanTel = tel.replace(/\D/g, '') // Rakamları al
      return cleanTel.length >= 10 && cleanTel.length <= 11 // Uzunluk kontrolü
    },

    sendFormDetails() {
      this.formState.tel = this.formattedPhoneNumber(this.formState.tel) // Telefon numarasını formatla
      // API'ye post etme işlemi
      this.loadings.form_submit = true
      this.$restMethods.postForWebApp('/settings-page-submit', this.formState, { show_error: false }).then(response => {
        if ( ! response.hasError()) {
          const { message } = response.getData()
          this.openNotificationWithIcon('success', this.$t.successful, message)
          this.formState.tel = this.unformatPhoneNumber(this.formState.tel) // Telefon numarasını eski haline çevir
        } else {
          this.openNotificationWithIcon('error', this.$t.error, response.getErrorMessage())
        }
        this.loadings.form_submit = false // İşlem bittiğinde yükleme ikonunu deaktif et
      })
    },
    openNotificationWithIcon(type, getMessage, getDescription) {
      notification[type]({
        message: getMessage,
        description: getDescription,
      })
    },
  },
}
</script>